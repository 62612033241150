import {
  createInboundPaymentRequest,
  fetchEscrowAccount,
  PaymentRequestSourceParams,
  searchEscrowAccounts,
} from "@earnnest/earnnest-ui-web-library/src/api";
import {
  useAuthenticatedApiEndpoint,
  useAuthMode,
} from "@earnnest/earnnest-ui-web-library/src/Auth/Auth";
import {
  AgentRole,
  AuthModes,
  UserRoles,
} from "@earnnest/earnnest-ui-web-library/src/common";
import { useRequiredConfig } from "@earnnest/earnnest-ui-web-library/src/Config/Config";
import { useMammonCelebrationPreloader } from "@earnnest/earnnest-ui-web-library/src/MammonCelebration/MammonCelebration";
import {
  MammonNotificationType,
  useMammonNotifications,
} from "@earnnest/earnnest-ui-web-library/src/MammonNotifications/MammonNotifications";
import {
  RequiredUserContext,
  useUser,
} from "@earnnest/earnnest-ui-web-library/src/User";
import { useSearchParams } from "@earnnest/earnnest-ui-web-library/src/useSearchParams";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGetHelpOverlay } from "../GetHelpOverlayProvider";
import { useDefaultLocationCreatorForPortal } from "../useDefaultLocationCreatorForPortal";
import {
  RequestPortalScreen,
  UserPreparationMode,
} from "./RequestPortalScreen";

// The route handles any setup or pageantry at the edge or transition of the
// screen, it does not handle implementation internal to the screen
export function RequestPortalRoute() {
  const { toggle: toggleGetHelpOverlay } = useGetHelpOverlay();
  const { user, updateCurrentUser } = useUser() as RequiredUserContext;
  const { displayNotification } = useMammonNotifications();
  const { config } = useRequiredConfig();
  const authMode = useAuthMode();
  const history = useHistory();
  const location = useLocation();
  const searchParams = useSearchParams();
  const createLocation = useDefaultLocationCreatorForPortal();

  // Determines if the user should be shown the Welcome Overlay
  const userPreparationMode =
    authMode === AuthModes.Legacy
      ? UserPreparationMode.InsertionTimeBased
      : UserPreparationMode.RoleBased;

  const query = useMemo(() => {
    return Object.fromEntries(searchParams);
  }, [searchParams]);

  // Only at the route level should we know about the details of the query
  // params. So let's pull that off and translate it to what our Screen
  // expects
  const {
    source,
    correlation_id: sourceCorrelationId,
    generated,
    notify,
    selected_role: selectedRole,
    remitter_first_name: remitterFirstName,
    remitter_last_name: remitterLastName,
    remitter_email: remitterEmail,
    remitter_phone: remitterPhone,
    requestor_email: requestorEmail,
    property_address: propertyAddress,
    property_address_2: propertyAddress2,
    property_city: propertyCity,
    property_state_or_province: propertyState,
    property_postal_code: propertyZip,
    amount,
    escrow_account_id: escrowAccountIdFromQuery,
    created_by_id: createdById,
  } = query;

  const isFormGeneratedByARequestorLink = generated === "true";

  const isListingAgentFlowDisabled =
    authMode === AuthModes.Legacy || config.portalListingAgents !== "true";
  const isPrefillingEscrowAccountDisabled = authMode === AuthModes.Legacy;

  // We don't want prefilled values to change if the query ever becomes reactive
  const [prefilledRole] = useState(() => {
    return selectedRole;
  });

  const [prefilledEscrowAccountId] = useState(() => {
    return escrowAccountIdFromQuery;
  });

  const [prefilledCreatedById] = useState(() => {
    return createdById;
  });

  const [prefilledFormValues] = useState(() => {
    return {
      ...(remitterFirstName ? { remitterFirstName } : {}),
      ...(remitterLastName ? { remitterLastName } : {}),
      ...(remitterEmail ? { remitterEmail } : {}),
      ...(remitterPhone ? { remitterPhone } : {}),
      ...(requestorEmail ? { requestorEmail } : {}),
      ...(propertyAddress ? { propertyAddress } : {}),
      ...(propertyAddress2 ? { propertyAddress2 } : {}),
      ...(propertyCity ? { propertyCity } : {}),
      ...(propertyState ? { propertyState } : {}),
      ...(propertyZip ? { propertyZip } : {}),
      ...(amount ? { amount } : {}),
    };
  });

  const paymentRequestSource: PaymentRequestSourceParams = useMemo(() => {
    return { source, sourceCorrelationId, createdById: prefilledCreatedById };
  }, [source, sourceCorrelationId, prefilledCreatedById]);

  const additionalEmailsToBeNotified = useMemo(() => {
    return notify ? notify.split(",").map(email => email.toLowerCase()) : [];
  }, [notify]);

  const escrowHolderInvitationUrl = useMemo(() => {
    const url = new URL("https://earnnest.com");
    url.pathname = "/solutions/agents";
    url.hash = "escrow-invite";
    url.searchParams.append("utc_medium", "website");
    url.searchParams.append("utc_source", "request_portal");
    url.searchParams.append("utc_content", paymentRequestSource.source);
    return url;
  }, [paymentRequestSource.source]);

  const searchEscrowAccountsWithAuth = useAuthenticatedApiEndpoint(
    searchEscrowAccounts,
  );
  const fetchEscrowAccountWithAuth = useAuthenticatedApiEndpoint(
    fetchEscrowAccount,
  );
  const createInboundPaymentRequestWithAuth = useAuthenticatedApiEndpoint(
    createInboundPaymentRequest,
  );
  // Add payment request source info here because query will not change
  // and so that inner components don't have to manage it
  const createInboundPaymentRequestWithAuthAndSourceInfo = useCallback(
    (values: any) => {
      return createInboundPaymentRequestWithAuth(paymentRequestSource, values);
    },
    [createInboundPaymentRequestWithAuth, paymentRequestSource],
  );

  const handleFormSubmitted = useCallback(
    (role: AgentRole, resourceId: string) => {
      const nextPath = role === AgentRole.Requestor ? "payer" : "requestor";
      const newPathname = `/request/${nextPath}/${resourceId}`;
      history.replace(createLocation({ pathname: newPathname }));
    },
    [createLocation, history],
  );

  const handleRoleSelected = useCallback(
    (role: AgentRole) => {
      // Avoid mutating searchParams directly
      const updatedSearchParams = new URLSearchParams(searchParams);
      updatedSearchParams.set("selected_role", role);
      history.replace(
        createLocation({
          pathname: location.pathname,
          search: updatedSearchParams.toString(),
        }),
      );
    },
    [createLocation, history, location.pathname, searchParams],
  );

  const prepareUserForPaymentRequest = useCallback(async () => {
    // If user is in legacy auth mode, there's no need to perform the update
    if (authMode === AuthModes.Legacy) {
      Promise.resolve(null);
      return;
    }
    await updateCurrentUser({
      roles: [...user.roles, UserRoles.RequestEscrow],
    });
  }, [authMode, updateCurrentUser, user.roles]);

  const handleUserPreparationError = useCallback(
    (error: Error) => {
      displayNotification({
        type: MammonNotificationType.Error,
        message: <>We encountered an error with that request.</>,
        dismissable: true,
      });
    },
    [displayNotification],
  );

  const handleFormSubmissionError = useCallback(
    (error: Error) => {
      // TODO: When we can trust the errors that come back from the server:
      // const message = <>{error.message}</>;
      const message = (
        <>We encountered an error submitting this payment request.</>
      );

      displayNotification({
        type: MammonNotificationType.Error,
        message,
        dismissable: true,
      });
    },
    [displayNotification],
  );

  const handleShowHelpOverlay = useCallback(() => {
    toggleGetHelpOverlay(true);
  }, [toggleGetHelpOverlay]);

  // Ensure our mammon celebration is properly preloaded in order to
  // make a smooth transition
  useMammonCelebrationPreloader(() => {
    console.debug("[RequestPortalRoute] Celebration component preloaded.");
  });

  return (
    <RequestPortalScreen
      paymentRequestSource={paymentRequestSource}
      prefilledRole={prefilledRole}
      prefilledFormValues={prefilledFormValues}
      prefilledEscrowAccountId={prefilledEscrowAccountId}
      additionalEmailsToBeNotified={additionalEmailsToBeNotified}
      isListingAgentFlowDisabled={isListingAgentFlowDisabled}
      isPrefillingEscrowAccountDisabled={isPrefillingEscrowAccountDisabled}
      isFormGeneratedByARequestorLink={isFormGeneratedByARequestorLink}
      userPreparationMode={userPreparationMode}
      user={user}
      escrowHolderInvitationUrl={escrowHolderInvitationUrl}
      searchEscrowAccounts={searchEscrowAccountsWithAuth}
      fetchEscrowAccount={fetchEscrowAccountWithAuth}
      createInboundPaymentRequest={
        createInboundPaymentRequestWithAuthAndSourceInfo
      }
      prepareUserForPaymentRequest={prepareUserForPaymentRequest}
      onUserPreparationError={handleUserPreparationError}
      onRoleSelected={handleRoleSelected}
      onFormSubmitted={handleFormSubmitted}
      onFormSubmissionError={handleFormSubmissionError}
      onShowHelpOverlay={handleShowHelpOverlay}
    />
  );
}
