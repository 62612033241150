import {
  CreateInboundPaymentRequestFunc,
  SearchEscrowAccountFunc,
} from "@earnnest/earnnest-ui-web-library/src/api";
import {
  AgentRole,
  ProcessingStates,
} from "@earnnest/earnnest-ui-web-library/src/common";
import { IconSize } from "@earnnest/earnnest-ui-web-library/src/Icon";
import { IconCaretDown } from "@earnnest/earnnest-ui-web-library/src/IconCaretDown";
import {
  MammonButton,
  MammonButtonState,
} from "@earnnest/earnnest-ui-web-library/src/MammonButton/MammonButton";
import { MammonButtonActionText } from "@earnnest/earnnest-ui-web-library/src/MammonButtonActionText/MammonButtonActionText";
import { MammonCardLayoutFooter } from "@earnnest/earnnest-ui-web-library/src/MammonCardLayout/MammonCardLayout";
import { MammonInputBoxType } from "@earnnest/earnnest-ui-web-library/src/MammonForm/MammonInput";
import {
  ConnectedMammonCheckboxFieldAndInput,
  ConnectedMammonFieldAndInput,
  MammonProvideForm,
  useMammonFormBuilder,
} from "@earnnest/earnnest-ui-web-library/src/MammonForm/useMammonForm";
import { MammonOverlay } from "@earnnest/earnnest-ui-web-library/src/MammonOverlay/MammonOverlay";
import { StateAbbreviationInput } from "@earnnest/earnnest-ui-web-library/src/MaskedInput/StateAbbreviationInput";
import { USDollarInput } from "@earnnest/earnnest-ui-web-library/src/MaskedInput/USDollarInput";
import { USPhoneInput } from "@earnnest/earnnest-ui-web-library/src/MaskedInput/USPhoneInput";
import { USZip4Input } from "@earnnest/earnnest-ui-web-library/src/MaskedInput/USZip4Input";
import { runPromiseForMinimumTime } from "@earnnest/earnnest-ui-web-library/src/promiseHelpers";
import {
  formatNumberAsUsd,
  isValidEmail,
} from "@earnnest/earnnest-ui-web-library/src/sharedStringHelpers";
import {
  InlineChild,
  InlineContainer,
} from "@earnnest/earnnest-ui-web-library/src/Stack/Inline";
import { Stack } from "@earnnest/earnnest-ui-web-library/src/Stack/Stack";
import { User } from "@earnnest/earnnest-ui-web-library/src/User";
import omit from "lodash/omit";
import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Button, Flex, Text, useThemeUI } from "theme-ui";
import { useEffectReducer } from "use-effect-reducer";
import { EscrowSearchOverlayContent } from "./EscrowSearchOverlayContent";
import {
  sanitizePaymentRequest,
  validatePaymentRequest,
} from "./paymentRequestHelpers";

export type PaymentRequestFormValues = {
  remitterFirstName: string;
  remitterLastName: string;
  remitterEmail: string;
  remitterPhone: string;
  requestorEmail: string;
  amount: string;
  propertyAddress: string;
  propertyAddress2: string;
  propertyCity: string;
  propertyState: string;
  propertyZip: string;
  selectedEscrowAccount: any | null;
  escrowAccountMatchesContract: boolean;
  fullyExecutedContract: boolean;
  addedEmails: string[];
  emailToBeAdded: string;
};

export type PaymentRequestFormValuesKeys = keyof PaymentRequestFormValues;

export type PaymentRequestFormDirty = {
  [k in PaymentRequestFormValuesKeys]: boolean;
};

export type PrefilledPaymentRequestFormValues = Partial<
  PaymentRequestFormValues
>;

export type RequestPortalFormProps = {
  additionalEmailsToBeNotified: string[];
  isFormGeneratedByARequestorLink: boolean;
  user: User;
  selectedRole: AgentRole | null;
  prefilledFormValues: PrefilledPaymentRequestFormValues;
  searchEscrowAccounts: SearchEscrowAccountFunc;
  createInboundPaymentRequest: CreateInboundPaymentRequestFunc;
  escrowHolderInvitationUrl: URL;
  onFormSubmitted: (role: AgentRole, resourceId: string) => void;
  onFormSubmissionError: (error: Error) => void;
};

export function RequestPortalForm({
  prefilledFormValues,
  additionalEmailsToBeNotified,
  isFormGeneratedByARequestorLink,
  user,
  selectedRole,
  escrowHolderInvitationUrl,
  createInboundPaymentRequest,
  searchEscrowAccounts,
  onFormSubmitted,
  onFormSubmissionError,
}: RequestPortalFormProps) {
  const { theme } = useThemeUI();

  const sanitizedPrefilledValues = useMemo(() => {
    return sanitizePaymentRequest(prefilledFormValues);
  }, [prefilledFormValues]);

  const defaultValues = useMemo(() => {
    return {
      remitterFirstName: "",
      remitterLastName: "",
      remitterEmail: "",
      remitterPhone: "",
      requestorEmail: "",
      amount: "",
      propertyAddress: "",
      propertyAddress2: "",
      propertyCity: "",
      propertyState: "",
      propertyZip: "",
      selectedEscrowAccount: null,
      escrowAccountMatchesContract: false,
      fullyExecutedContract: false,
      addedEmails: [],
      emailToBeAdded: "",
    };
  }, []);

  const initialValues = useMemo(() => {
    return { ...defaultValues, ...sanitizedPrefilledValues };
  }, [defaultValues, sanitizedPrefilledValues]);

  // Mark all the values dirty that are prefilled
  const initialDirty = useMemo(() => {
    const valuesKeys = Object.keys(
      initialValues,
    ) as PaymentRequestFormValuesKeys[];
    const dirty = valuesKeys.reduce((dirtyObj, key) => {
      return { ...dirtyObj, [key]: Boolean(prefilledFormValues[key]) };
    }, {} as PaymentRequestFormDirty);
    return dirty;
  }, [initialValues, prefilledFormValues]);

  function isFieldLocked(key: PaymentRequestFormValuesKeys): boolean {
    return isFormGeneratedByARequestorLink && initialDirty[key];
  }

  const [state, dispatch] = useEffectReducer(
    (
      state: {
        showEscrowSearchOverlay: boolean;
        createdPaymentRequest: any;
        generatedLink: string | null;
      },
      action:
        | { type: "select_escrow_holder_clicked" }
        | { type: "escrow_holder_overlay_closed" }
        | { type: "select_escrow_account"; payload: boolean }
        | { type: "remove_selected_escrow_account" }
        | {
            type: "form_submitted";
            payload: { requestType: string; requestData: any };
          }
        | {
            type: "email_updated";
            payload: string;
          }
        | {
            type: "email_added";
          }
        | {
            type: "email_removed";
            payload: string;
          },
      exec,
    ) => {
      switch (action.type) {
        case "select_escrow_holder_clicked":
          return { ...state, showEscrowSearchOverlay: true };
        case "escrow_holder_overlay_closed":
          return { ...state, showEscrowSearchOverlay: false };
        case "select_escrow_account":
          const selectedEscrowAccount = action.payload;
          exec(() => {
            setFormValues(
              (prevValues: any) => {
                return { ...prevValues, selectedEscrowAccount };
              },
              ["selectedEscrowAccount"],
            );
          });
          return { ...state, showEscrowSearchOverlay: false };
        case "remove_selected_escrow_account":
          exec(() => {
            setFormValues(
              (prevValues: any) => {
                return {
                  ...prevValues,
                  selectedEscrowAccount: null,
                  escrowAccountMatchesContract: false,
                };
              },
              ["selectedEscrowAccount", "escrowAccountMatchesContract"],
            );
          });
          return { ...state };
        case "form_submitted":
          if (action.payload.requestType === "requestor") {
            return { ...state, generatedLink: action.payload.requestData };
          }
          if (action.payload.requestType === "payer") {
            return {
              ...state,
              createdPaymentRequest: action.payload.requestData,
            };
          }
          return state;
        case "email_updated":
          const emailText = action.payload;
          exec(() => {
            setFormValues(
              (prevValues: any) => {
                return {
                  ...prevValues,
                  emailToBeAdded: emailText,
                };
              },
              ["emailToBeAdded"],
            );
          });
          return state;
        case "email_added":
          exec(() => {
            setFormValues(
              (prevValues: any) => {
                return {
                  ...prevValues,
                  addedEmails: [
                    ...prevValues.addedEmails,
                    prevValues.emailToBeAdded,
                  ],
                  emailToBeAdded: "",
                };
              },
              ["addedEmails", "emailToBeAdded"],
            );
          });
          return state;
        case "email_removed":
          const emailToBeRemoved = action.payload;
          exec(() => {
            setFormValues(
              (prevValues: any) => {
                return {
                  ...prevValues,
                  addedEmails: (prevValues.addedEmails as string[]).filter(
                    ae => ae !== emailToBeRemoved,
                  ),
                };
              },
              ["addedEmails"],
            );
          });
          return state;
        default:
          throw new Error(
            "[RequestPortalScreen] Unrecognized action in screen level dispatcher",
          );
      }
    },
    () => {
      return {
        showEscrowSearchOverlay: false,
        generatedLink: null,
        createdPaymentRequest: null,
      };
    },
  );

  const handleFormSubmit = useCallback(
    async values => {
      // If we get here, we know the form is already valid.
      const amountInCents = values?.amount
        ? `${values.amount}00`
        : values?.amount;
      const allEmails = [
        ...additionalEmailsToBeNotified,
        ...values.addedEmails,
      ];
      const toSendToServer = omit(
        {
          ...values,
          amount: amountInCents, // Unified backend wants cents
          ...(allEmails.length > 0 ? { emails: allEmails } : {}),
        },
        selectedRole === AgentRole.Creator
          ? // if creator omit requestor specific fields
            [
              "remitterEmail",
              "remitterPhone",
              "remitterFirstName",
              "remitterLastName",
              "amount",
            ]
          : // if requestor omit creator specific
            ["requestorEmail"],
      );
      console.log("[Request Portal] Submitting payment request.");
      console.log(JSON.stringify(toSendToServer));
      const { requestType, requestData } = await runPromiseForMinimumTime<{
        requestType: string;
        requestData: any;
      }>(createInboundPaymentRequest!(toSendToServer));
      dispatch({
        type: "form_submitted",
        payload: { requestType, requestData },
      });

      // Error handling is done by the form handler changing the processing
      // state to be an error, and an effect below listens for that state.
      // We simply display a notification that an error has occurred.
    },
    [
      createInboundPaymentRequest,
      dispatch,
      additionalEmailsToBeNotified,
      selectedRole,
    ],
  );

  const validateWithRoleEmbedded = useCallback(
    values => {
      return validatePaymentRequest(selectedRole as AgentRole, values);
    },
    [selectedRole],
  );

  // Find the first non-dirty field to receive initial focus
  const dirtyKeys = Object.keys(initialDirty) as PaymentRequestFormValuesKeys[];
  const initialFocus = dirtyKeys.find(key => {
    return initialDirty[key] === false;
  });

  const form = useMammonFormBuilder<PaymentRequestFormValues>({
    initialFocus,
    initialValues,
    initialDirty,
    validate: validateWithRoleEmbedded,
    submit: handleFormSubmit,
  });

  const { submissionState, submissionError, setValues: setFormValues } = form;
  const hidePropertyAddress2 =
    isFieldLocked("propertyAddress") && !form.values.propertyAddress2;

  const roleDependentFormConfig = useMemo(() => {
    const config = {
      [AgentRole.Requestor]: {
        pageTitle: "Request Payment",
        detailsHeader: "What are the details?",
        submitButtonText: `Request ${formatNumberAsUsd(
          form.values.amount || 0,
        )}`,
        showBuyerInfoSection: true,
        showInviteRequestorSection: false,
        showEarnestMoneySection: true,
        showFullyExecutedCheck: true,
      },
      [AgentRole.Creator]: {
        pageTitle: "Setup Payment",
        detailsHeader: "What's the property?",
        submitButtonText: "Send",
        showBuyerInfoSection: false,
        showInviteRequestorSection: true,
        showEarnestMoneySection: false,
        showFullyExecutedCheck: false,
      },
    };
    return config[(selectedRole as AgentRole) || AgentRole.Requestor];
  }, [selectedRole, form.values.amount]);

  const emailsToBeNotified = useMemo(() => {
    const allEmails = [
      user.email,
      selectedRole === AgentRole.Creator
        ? form.values.requestorEmail
        : form.values.remitterEmail,
      form.values.selectedEscrowAccount?.organizationEmail,
      ...additionalEmailsToBeNotified,
    ];
    const presentEmails = allEmails.filter(e => Boolean(e));
    const uniqueEmails = [...new Set(presentEmails)];
    return uniqueEmails;
  }, [
    user.email,
    selectedRole,
    form.values.requestorEmail,
    form.values.remitterEmail,
    form.values.selectedEscrowAccount,
    additionalEmailsToBeNotified,
  ]);

  const userDisplayName = useMemo(() => {
    return [user.firstName, user.lastName].filter(p => Boolean(p)).join(" ");
  }, [user.firstName, user.lastName]);

  const isAdditionalEmailInputValid =
    !emailsToBeNotified.includes(form.values.emailToBeAdded) &&
    !form.values.addedEmails.includes(form.values.emailToBeAdded) &&
    isValidEmail(form.values.emailToBeAdded);

  const handleClickSelectEscrowHolder = useCallback(() => {
    dispatch({ type: "select_escrow_holder_clicked" });
  }, [dispatch]);

  const handleCloseEscrowSearchOverlay = useCallback(() => {
    dispatch({ type: "escrow_holder_overlay_closed" });
  }, [dispatch]);

  const handleSelectEscrowAccount = useCallback(
    selectedEscrowAccount => {
      dispatch({
        type: "select_escrow_account",
        payload: selectedEscrowAccount,
      });
    },
    [dispatch],
  );

  const handleClickRemoveEscrowHolder = useCallback(() => {
    if (window.confirm("Remove designated escrow holder?")) {
      dispatch({ type: "remove_selected_escrow_account" });
    }
  }, [dispatch]);

  useEffect(() => {
    if (submissionState === ProcessingStates.Processed) {
      let resourceId;
      if (selectedRole === AgentRole.Requestor) {
        resourceId = state.createdPaymentRequest.id;
      } else if (selectedRole === AgentRole.Creator) {
        resourceId = window.btoa(encodeURIComponent(state.generatedLink!));
      } else {
        throw new Error(
          "Invalid agent role supplied in processed form handler",
        );
      }
      onFormSubmitted(selectedRole, resourceId);
    }
  }, [
    onFormSubmitted,
    selectedRole,
    state.createdPaymentRequest,
    state.generatedLink,
    submissionState,
  ]);

  useEffect(() => {
    if (submissionState === ProcessingStates.Error) {
      console.log(
        "[RequestPortal] Error occurred submitting inbound payment request.",
      );
      console.error(submissionError);
      onFormSubmissionError(submissionError);
    }
  }, [submissionState, submissionError, onFormSubmissionError]);

  return (
    <>
      <form
        id="add-email-form"
        style={{ display: "none" }}
        onSubmit={e => {
          e.preventDefault();
          if (!isAdditionalEmailInputValid) {
            return;
          }
          dispatch({ type: "email_added" });
        }}
      />
      <MammonOverlay onClose={handleCloseEscrowSearchOverlay}>
        {state.showEscrowSearchOverlay && (
          <EscrowSearchOverlayContent
            onClose={handleCloseEscrowSearchOverlay}
            propertyState={form.values.propertyState}
            inviteHref={escrowHolderInvitationUrl.href}
            searchEscrowAccounts={searchEscrowAccounts!}
            onSelect={handleSelectEscrowAccount}
          />
        )}
      </MammonOverlay>
      <MammonProvideForm form={form}>
        <form id="request-portal-form" onSubmit={form.handleSubmit}>
          <Box>
            <Stack gap={4 * 8}>
              <Stack gap={4 * 2}>
                <Text variant="body.pageTitle">
                  {roleDependentFormConfig?.pageTitle}
                </Text>
                <Text variant="body.paragraph">
                  {userDisplayName}, we’ve added in the information you have
                  already supplied. Fill out any remaining details.
                </Text>
              </Stack>
              {roleDependentFormConfig?.showBuyerInfoSection && (
                <>
                  <Box bg="white" sx={{ height: 1 }} />
                  <Stack gap={4 * 4}>
                    <Text variant="body.subPageTitle">Who's paying?</Text>
                    <Stack gap={4 * 3}>
                      <Stack gap={4 * 2}>
                        <Text variant="body.subSectionTitle">Their name</Text>
                        <Box>
                          <InlineContainer gap={4 * 4}>
                            <InlineChild sx={{ width: "50%" }}>
                              <ConnectedMammonFieldAndInput
                                fieldKey="remitterFirstName"
                                boxType={MammonInputBoxType.Underline}
                                type="text"
                                label="First name"
                                name="remitter-fn"
                                autoComplete="dontcomplete"
                              />
                            </InlineChild>
                            <InlineChild sx={{ width: "50%" }}>
                              <ConnectedMammonFieldAndInput
                                fieldKey="remitterLastName"
                                boxType={MammonInputBoxType.Underline}
                                type="text"
                                label="Last name"
                                name="remitter-ln"
                                autoComplete="dontcomplete"
                              />
                            </InlineChild>
                          </InlineContainer>
                        </Box>
                      </Stack>
                      <Stack gap={4 * 2}>
                        <Text variant="body.subSectionTitle">
                          Their contact info
                        </Text>
                        <Box>
                          <InlineContainer gap={4 * 4}>
                            <InlineChild sx={{ width: "50%" }}>
                              <ConnectedMammonFieldAndInput
                                fieldKey="remitterEmail"
                                boxType={MammonInputBoxType.Underline}
                                type="email"
                                label="Email address"
                                name="remitter-e"
                                autoComplete="dontcomplete"
                              />
                            </InlineChild>
                            <InlineChild sx={{ width: "50%" }}>
                              <ConnectedMammonFieldAndInput
                                fieldKey="remitterPhone"
                                boxType={MammonInputBoxType.Underline}
                                as={USPhoneInput}
                                type="tel"
                                label="Mobile phone"
                                name="remitter-p"
                                autoComplete="dontcomplete"
                                placeholder="###-###-####"
                                assist={
                                  <>Must be able to receive SMS messages</>
                                }
                              />
                            </InlineChild>
                          </InlineContainer>
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              )}
              {roleDependentFormConfig?.showInviteRequestorSection && (
                <>
                  <Box bg="white" sx={{ height: 1 }} />
                  <Stack gap={4 * 4}>
                    <Text variant="body.subPageTitle">
                      Who has the payer's info?
                    </Text>
                    <Stack gap={4 * 2}>
                      <Text variant="body.subSectionTitle">
                        This is the person who has the contact information of
                        the person paying. Often this is the buyer’s agent.
                      </Text>
                      <Box>
                        <ConnectedMammonFieldAndInput
                          fieldKey="requestorEmail"
                          boxType={MammonInputBoxType.Underline}
                          type="email"
                          label="Email address"
                          name="requestor-e"
                          autoComplete="dontcomplete"
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </>
              )}
              <Box bg="white" sx={{ height: 1 }} />
              <Stack gap={4 * 4}>
                <Text variant="body.subPageTitle">
                  {roleDependentFormConfig?.detailsHeader}
                </Text>
                <Stack gap={4 * 4}>
                  {roleDependentFormConfig?.showEarnestMoneySection && (
                    <Stack gap={4 * 2}>
                      <Text variant="body.subSectionTitle">
                        Earnest money deposit
                      </Text>
                      <Box>
                        <InlineContainer gap={4 * 4}>
                          <InlineChild sx={{ width: "50%" }}>
                            <ConnectedMammonFieldAndInput
                              fieldKey="amount"
                              boxType={MammonInputBoxType.Underline}
                              as={USDollarInput}
                              type="tel"
                              inputMode="decimal"
                              label="Amount"
                              name="earnest-money-a"
                              autoComplete="dontcomplete"
                              placeholder="$0.00"
                            />
                          </InlineChild>
                        </InlineContainer>
                      </Box>
                    </Stack>
                  )}
                  <Stack gap={4 * 2}>
                    <Text variant="body.subSectionTitle">
                      The property address
                    </Text>
                    {isFieldLocked("propertyAddress") && (
                      <Text variant="body.paragraph">
                        This information has already been set and can’t be
                        edited.
                      </Text>
                    )}
                    <Stack gap={0}>
                      <Box>
                        <InlineContainer gap={4 * 4}>
                          <InlineChild
                            sx={{
                              width: hidePropertyAddress2 ? "100%" : "66.66%",
                            }}
                          >
                            <ConnectedMammonFieldAndInput
                              fieldKey="propertyAddress"
                              boxType={MammonInputBoxType.Underline}
                              type="text"
                              label="Street address"
                              name="earnest-money-pa"
                              autoComplete="dontcomplete"
                              readOnly={isFieldLocked("propertyAddress")}
                            />
                          </InlineChild>
                          {!hidePropertyAddress2 && (
                            <InlineChild sx={{ width: "33.33%" }}>
                              <ConnectedMammonFieldAndInput
                                fieldKey="propertyAddress2"
                                boxType={MammonInputBoxType.Underline}
                                type="text"
                                label="Address 2"
                                name="earnest-money-pa-2"
                                autoComplete="dontcomplete"
                                readOnly={
                                  isFieldLocked("propertyAddress2") ||
                                  isFieldLocked("propertyAddress")
                                }
                              />
                            </InlineChild>
                          )}
                        </InlineContainer>
                      </Box>
                      <Box>
                        <InlineContainer gap={4 * 4}>
                          <InlineChild sx={{ width: "66.66%" }}>
                            <ConnectedMammonFieldAndInput
                              fieldKey="propertyCity"
                              boxType={MammonInputBoxType.Underline}
                              type="text"
                              label="City"
                              name="earnest-money-pc"
                              autoComplete="dontcomplete"
                              readOnly={isFieldLocked("propertyCity")}
                            />
                          </InlineChild>
                          <InlineChild sx={{ width: "33.33%" }}>
                            <ConnectedMammonFieldAndInput
                              fieldKey="propertyState"
                              boxType={MammonInputBoxType.Underline}
                              as={StateAbbreviationInput}
                              type="text"
                              label="State"
                              name="earnest-money-ps"
                              autoComplete="dontcomplete"
                              readOnly={isFieldLocked("propertyState")}
                            />
                          </InlineChild>
                        </InlineContainer>
                      </Box>
                      <Box>
                        <InlineContainer gap={4 * 4}>
                          <InlineChild sx={{ width: "33.33%" }}>
                            <ConnectedMammonFieldAndInput
                              fieldKey="propertyZip"
                              boxType={MammonInputBoxType.Underline}
                              as={USZip4Input}
                              type="tel"
                              label="Zip code"
                              name="earnest-money-pz"
                              autoComplete="dontcomplete"
                              readOnly={isFieldLocked("propertyZip")}
                            />
                          </InlineChild>
                        </InlineContainer>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Box bg="white" sx={{ height: 1 }} />
              <Stack gap={4 * 4}>
                <Text variant="body.subPageTitle">
                  Who's receiving the payment?
                </Text>
                <Stack gap={4 * 3}>
                  <Stack gap={4 * 2}>
                    <Text variant="body.subSectionTitle">
                      The escrow holder
                    </Text>
                    {isFieldLocked("selectedEscrowAccount") && (
                      <Text variant="body.paragraph">
                        This information has already been set and can’t be
                        edited.
                      </Text>
                    )}
                    <Stack gap={16}>
                      <SelectedEscrowHolderFieldBox
                        fieldKey="selectedEscrowAccount"
                        disabled={
                          form.errors.propertyState ||
                          isFieldLocked("selectedEscrowAccount")
                        }
                        readOnly={isFieldLocked("selectedEscrowAccount")}
                        selectedEscrowAccount={
                          form.values.selectedEscrowAccount
                        }
                        onClickSelectEscrowHolder={
                          handleClickSelectEscrowHolder
                        }
                        onClickRemoveEscrowHolder={
                          handleClickRemoveEscrowHolder
                        }
                      />
                      {!isFieldLocked("escrowAccountMatchesContract") && (
                        <Box>
                          <ConnectedMammonCheckboxFieldAndInput
                            fieldKey="escrowAccountMatchesContract"
                            disabled={!form.values.selectedEscrowAccount}
                            label={
                              <>
                                The above listed Escrow Holder is the one on the
                                purchase contract.
                              </>
                            }
                            size="sm"
                          />
                        </Box>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Box bg="white" sx={{ height: 1 }} />
              <Stack gap={4 * 2}>
                <Text variant="body.sectionTitle">Notification receipts</Text>
                <Stack gap={4 * 4}>
                  <Text variant="body.paragraph">
                    These email addresses will receive receipt payment
                    notifications.
                  </Text>
                  <Stack gap={4 * 2}>
                    {emailsToBeNotified.map((email, index) => {
                      return (
                        <Box key={index}>
                          <Text
                            variant="body.subSectionTitle"
                            sx={{ wordBreak: "break-word" }}
                          >
                            {email}
                          </Text>
                        </Box>
                      );
                    })}
                    {(form.values.addedEmails as string[]).map(
                      (email, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              variant="body.subSectionTitle"
                              sx={{ wordBreak: "break-word" }}
                            >
                              {email}
                            </Text>
                            <Button
                              css={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                appearance: "none",
                                margin: 0,
                                padding: 0,
                                border: 0,
                                background: "none",
                                cursor: "pointer",
                                outline: "none",
                              }}
                              onClick={e => {
                                e.preventDefault();
                                dispatch({
                                  type: "email_removed",
                                  payload: email,
                                });
                              }}
                            >
                              <IconTrashRing
                                size={16}
                                color={theme.colors?.white as string}
                                bgColor={theme.colors?.error as string}
                              />
                            </Button>
                          </Box>
                        );
                      },
                    )}
                    <Box
                      css={{
                        display: "inline-flex",
                        flex: "1 1 auto",
                      }}
                    >
                      <Box
                        css={{
                          display: "inline-flex",
                          width: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          border: 0,
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Box
                          style={{
                            borderBottom: `2px solid ${theme.colors
                              ?.grey4 as string}`,
                            background: theme.colors?.white as string,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                            width: "100%",
                            padding: 4,
                          }}
                        >
                          <Button
                            type="submit"
                            form="add-email-form"
                            disabled={!isAdditionalEmailInputValid}
                            css={{
                              appearance: "none",
                              outline: "none",
                              border: 0,
                              padding: 4,
                              paddingBottom: 0,
                              paddingTop: 4,
                              paddingRight: 4,
                              background: "transparent",
                              cursor: isAdditionalEmailInputValid
                                ? "pointer"
                                : "not-allowed",
                            }}
                          >
                            <PlusSM color={theme.colors?.green as string} />
                          </Button>
                          <input
                            style={{
                              color: theme.colors?.grey1 as string,
                              border: 0,
                              margin: 0,
                              // padding: 4,
                              // paddingLeft: 0,
                              outline: "none",
                              borderRadius: "2px",
                              width: "100%",
                              fontFamily: "Ubuntu",
                              fontSize: 16 / 16 + "rem",
                              fontWeight: 500,
                              lineHeight: "140%",
                            }}
                            placeholder="add additional emails..."
                            value={form.values.emailToBeAdded}
                            onChange={e => {
                              const emailText = e.target.value;
                              const strippedAndLowered = emailText
                                .replaceAll(" ", "")
                                .toLowerCase();
                              dispatch({
                                type: "email_updated",
                                payload: strippedAndLowered,
                              });
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
              {roleDependentFormConfig?.showFullyExecutedCheck && (
                <Stack gap={32}>
                  <Box bg="white" sx={{ height: 1 }} />
                  <Stack gap={4 * 2}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ConnectedMammonCheckboxFieldAndInput
                        fieldKey="fullyExecutedContract"
                        label={
                          <Text variant="body.sectionTitle">
                            Fully executed contract
                          </Text>
                        }
                        size="sm"
                      />
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Text variant="body.paragraph">
                        Do not request funds without a fully executed contract
                      </Text>
                    </Box>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Box>
        </form>
      </MammonProvideForm>
      <FormFooter
        buttonText={roleDependentFormConfig?.submitButtonText}
        submissionState={form.submissionState}
        shouldSubmissionButtonBeDisabled={form.shouldSubmissionButtonBeDisabled}
      />
    </>
  );
}

function FormFooter({
  buttonText,
  submissionState,
  shouldSubmissionButtonBeDisabled,
}: {
  buttonText?: string;
  submissionState: ProcessingStates;
  shouldSubmissionButtonBeDisabled: boolean;
}) {
  const buttonState =
    submissionState === ProcessingStates.Processed
      ? MammonButtonState.Processed
      : submissionState === ProcessingStates.Processing
      ? MammonButtonState.Processing
      : shouldSubmissionButtonBeDisabled
      ? MammonButtonState.Disabled
      : MammonButtonState.Initial;
  return (
    <MammonCardLayoutFooter sticky={false}>
      {useMemo(
        () => (
          <Flex sx={{ justifyContent: "center", paddingBottom: 4 * 5 }}>
            <MammonButton
              form="request-portal-form"
              type="submit"
              size="large"
              fillMode="filled"
              color="dark"
              state={buttonState}
            >
              {buttonText}
            </MammonButton>
          </Flex>
        ),
        [buttonState, buttonText],
      )}
    </MammonCardLayoutFooter>
  );
}

function SelectedEscrowHolderFieldBox({
  fieldKey,
  disabled,
  readOnly,
  onClickSelectEscrowHolder = () => {},
  onClickRemoveEscrowHolder = () => {},
  selectedEscrowAccount,
}: {
  fieldKey: string;
  disabled: boolean;
  readOnly: boolean;
  onClickSelectEscrowHolder: () => void;
  onClickRemoveEscrowHolder: () => void;
  selectedEscrowAccount: any;
}) {
  return (
    <Box sx={{ position: "relative" }}>
      <Box>
        <ConnectedMammonFieldAndInput
          fieldKey={fieldKey}
          disabled={disabled}
          readOnly={readOnly}
          boxType={MammonInputBoxType.Outline}
          as="button"
          type="button"
          onClick={onClickSelectEscrowHolder}
          icon={readOnly ? null : <IconCaretDown size={IconSize.SM} />}
          children={
            selectedEscrowAccount ? (
              <>
                <>{selectedEscrowAccount.brandName}</>
                <> - </>
                <>{selectedEscrowAccount.bankFriendlyName}</>
              </>
            ) : (
              <>Select escrow holder</>
            )
          }
        />
      </Box>
      {!disabled && !readOnly && selectedEscrowAccount && (
        <Box
          sx={{
            display: "inline-flex",
            position: "absolute",
            right: 0,
            bottom: -4,
          }}
        >
          <MammonButtonActionText onClick={onClickRemoveEscrowHolder}>
            Remove escrow holder
          </MammonButtonActionText>
        </Box>
      )}
    </Box>
  );
}

function PlusSM({ size = 16, color = "currentColor" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2C9.5 1.17157 8.82843 0.5 8 0.5C7.17157 0.5 6.5 1.17157 6.5 2V6.5H2C1.17157 6.5 0.5 7.17157 0.5 8C0.5 8.82843 1.17157 9.5 2 9.5H6.5V14C6.5 14.8284 7.17157 15.5 8 15.5C8.82843 15.5 9.5 14.8284 9.5 14V9.5H14C14.8284 9.5 15.5 8.82843 15.5 8C15.5 7.17157 14.8284 6.5 14 6.5H9.5V2Z"
        fill={color}
      />
    </svg>
  );
}

function IconTrashRing({
  size = 24,
  bgColor = "currentColor",
  color = "white",
}) {
  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 24 24">
      <circle
        cx="12"
        cy="12"
        r="11"
        fill={bgColor}
        stroke={bgColor}
        strokeWidth={2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7V6C9 5.44772 9.44772 5 10 5H14C14.5523 5 15 5.44772 15 6V7H18C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9H17V18C17 18.5523 16.5523 19 16 19H8C7.44772 19 7 18.5523 7 18V9L6 9C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7H9ZM9 10V17H15V10H9ZM11 11.5C11.2761 11.5 11.5 11.7239 11.5 12V15C11.5 15.2761 11.2761 15.5 11 15.5C10.7239 15.5 10.5 15.2761 10.5 15V12C10.5 11.7239 10.7239 11.5 11 11.5ZM13.5 12C13.5 11.7239 13.2761 11.5 13 11.5C12.7239 11.5 12.5 11.7239 12.5 12V15C12.5 15.2761 12.7239 15.5 13 15.5C13.2761 15.5 13.5 15.2761 13.5 15V12Z"
        fill={color}
      />
    </svg>
  );
}
